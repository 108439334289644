@import '../../theme/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;

  // Product Image
  .imageContainer {
    height: 240px;
    background-color: var(--colors-surfaces-primary-1);
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      width: 100%;
      height: 240px;
      object-fit: cover;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      background-color: var(--colors-surfaces-primary-1);
    }

    .productPlaceholderImage {
      width: 60px;
      height: 60px;
    }
  }
}

.metaContainer {
  padding: 20px 20px 16px 20px;
  border-bottom: var(--colors-surfaces-primary-1) 1px solid;

  // Product title
  h2 {
    margin-bottom: 5px;
  }

  // Advanced pricing tag
  > div {
    margin-top: 8px;
  }

  // Product description
  > p {
    margin-top: 16px;
  }

  .pricingContainer {
    display: flex;
    flex-direction: row;

    .priceAddition {
      margin-left: 12px;
    }
  }
}

.floatingHeader {
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  background-color: var(--colors-surfaces-primary-0);

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);

  opacity: 0;
  transition:
    opacity 0.5s ease,
    transform 0s ease;

  &.show {
    opacity: 1;
  }

  h2 {
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px 70px;
    text-align: center;
  }
}
